<script>
import moment from 'moment'
import { loadScript } from '@paypal/paypal-js'
import { captureMembershipOrder, checkOrderStatus, createMembershipOrder } from '@/api/data'
import UpgradeDetail from '@/views/data/modal/UpgradeDetail.vue'

export default {
  name: 'MembershipUpgradeBatch',
  data () {
    return {
      selected: 66,
      num: 1,
      payShow: false,
      paySuccess: false,
      expireTime: moment().add(1, 'month').format('YYYY-MM-DD'),
      timer: null
    }
  },
  props: ['selectedRows', 'layerid'],
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  created () {
    const _this = this
    loadScript({
      // 'environment': 'sandbox',
      // 'client-id': 'Aea6Z6vCwKjwTXVaTKa2268YxNwo5Kja9lV_Tcm04zVeWYEXMrO316y5es7NnSwYvmhietyuQHMSP1bS',
      'client-id': 'AV2loXix91fcINCOXVhq9jnl289U1V8PH5ANYmVdnI4FmPZ7hEhoLUyX0blYxG5b7G59pM7E7JVpfpCk',
      'currency': 'JPY'
    })
      .then((paypal) => {
        paypal.Buttons({
            async createOrder () {
              try {
                const users = []
                for (let i = 0; i < _this.selectedRows.length; i++) {
                  users.push(_this.selectedRows[i].user_id)
                }
                const orderData = await createMembershipOrder({
                  num: _this.num,
                  type: _this.selected,
                  uid: users
                })

                if (orderData.id) {
                  return orderData.id
                } else {
                  const errorDetail = orderData?.details?.[0]
                  const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData)

                  throw new Error(errorMessage)
                }
              } catch (error) {
                console.error(error)
                _this.resultMessage(`Could not initiate PayPal Checkout...`)
              }
            },
            async onApprove (data, actions) {
              if (_this.timer) {
                clearInterval(_this.timer)
                _this.timer = null
              }
              _this.timer = setInterval(() => {
                checkOrderStatus({ orderId: data.orderID }).then(res => {
                  if (res.code === 0 && res.data === 1) {
                    _this.paySuccess = true
                    clearInterval(_this.timer)
                    setTimeout(function () {
                      _this.$parent.refreshTable()
                      _this.$layer.close(_this.layerid)
                    }, 2000)
                  }
                })
              }, 2000)
              try {
                const orderData = await captureMembershipOrder({
                  orderId: data.orderID
                })
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message

                const errorDetail = orderData?.details?.[0]

                if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                  // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                  // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                  return actions.restart()
                } else if (errorDetail) {
                  // (2) Other non-recoverable errors -> Show a failure message
                  throw new Error(`${errorDetail.description} (${orderData.debug_id})`)
                } else if (!orderData.purchase_units) {
                  throw new Error(JSON.stringify(orderData))
                } else {
                  console.log(
                    'Capture result',
                    orderData,
                    JSON.stringify(orderData, null, 2)
                  )
                  if (orderData.status === 'COMPLETED') {
                    _this.paySuccess = true
                    clearInterval(_this.timer)
                    setTimeout(function () {
                      _this.$parent.refreshTable()
                      _this.$layer.close(_this.layerid)
                    }, 2000)
                  }
                }
              } catch (error) {
                console.error(error)
              }
            }
          }
        ).render('#paypal-button-container')
      })
      .catch((err) => {
        console.error('failed to load the PayPal JS SDK script', err)
      })
  },
  methods: {
    moment,
    select (index) {
      // if (this.record.level_expire && moment().isBefore(this.record.level_expire) && this.record.user_level === 2) {
      //   this.$message.error('現在のPro+会員は期限切れになるまで降格をサポートしていません。')
      //   return
      // }
      this.selected = index
      if (index === 66) {
        this.num = 1
        // if (this.record.level_expire && moment().isBefore(this.record.level_expire)) {
        //   this.expireTime = moment(this.record.level_expire).add(12, 'month').format('YYYY-MM-DD')
        // } else {
        //   this.expireTime = moment().add(12, 'month').format('YYYY-MM-DD')
        // }
      } else {
        // if (this.record.level_expire && moment().isBefore(this.record.level_expire)) {
        //   this.expireTime = moment(this.record.level_expire).add(this.num, 'month').format('YYYY-MM-DD')
        // } else {
        //   this.expireTime = moment().add(this.num, 'month').format('YYYY-MM-DD')
        // }
      }
    },
    handleChangeNum (e) {
      this.num = e
      // if (this.record.level_expire && moment().isBefore(this.record.level_expire)) {
      //   this.expireTime = moment(this.record.level_expire).add(this.num, 'month').format('YYYY-MM-DD')
      // } else {
      //   this.expireTime = moment().add(this.num, 'month').format('YYYY-MM-DD')
      // }
    },
    resultMessage () {

    },
    onClose () {
      this.payShow = false
    },
    startPay () {
      this.payShow = true
    },
    showDetail () {
       this.showModal(UpgradeDetail, this.$t('user.membership.upgradeDetail'), 700, 600, { selectedRows: this.selectedRows, selected: this.selected, num: this.num })
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px;">
    <!--    <div>-->
    <!--      <a-alert message="現在は試用会員。" banner v-if="record.user_level==0" />-->
    <!--      <a-alert-->
    <!--        :message="'現在はPro会員,有効日は'+moment(record.level_expire).format('YYYY年MM月DD日')+'。'"-->
    <!--        banner-->
    <!--        v-if="record.user_level==1" />-->
    <!--      <a-alert-->
    <!--        :message="'現在はPro+会員,有効日は'+moment(record.level_expire).format('YYYY年MM月DD日')+'。'"-->
    <!--        banner-->
    <!--        v-if="record.user_level==2" />-->
    <!--    </div>-->
    <div style="margin-top: 8px;">
      <div style="display: flex;">
        <div class="ant-card membership-card" style="width: 200px;">
          <div class="membership-card-header" style="border-bottom: 1px solid #eee;">{{ $t('user.membership.function')
          }}
          </div>
          <div class="membership-card-body">
            <div class="fun-item">{{ $t('user.membership.function.data.limit') }}</div>
            <div class="fun-item">{{ $t('user.membership.function.data.sync') }}</div>
            <div class="fun-item">{{ $t('user.membership.function.excel') }}</div>
            <div class="fun-item">{{ $t('user.membership.function.location') }}</div>
            <div class="fun-item">{{ $t('user.membership.function.video') }}</div>
          </div>
        </div>
        <div style="flex:1;overflow-x:auto;overflow-y:hidden;margin-left: 8px;">
          <div style="width: 1000px;">
            <a-row :gutter="8">
              <a-col :span="6">
                <div
                  class="ant-card membership-card card-grey cursor"
                  :class="selected==0?'card-selected':''"
                  @click="select(0)">
                  <div class="membership-card-header">試用会員（無料）</div>
                  <div class="membership-card-body">
                    <div class="fun-item">3{{ $t('user.membership.days') }}</div>
                    <div class="fun-item">×</div>
                    <div class="fun-item">×</div>
                    <div class="fun-item">×</div>
                    <div class="fun-item">×</div>
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div
                  class="ant-card membership-card card-copper cursor"
                  :class="selected==1?'card-selected':''"
                  @click="select(1)">
                  <div class="membership-card-header">Pro会員（500円/月）</div>
                  <div class="membership-card-body">
                    <div class="fun-item">{{ $t('user.membership.limit.none') }}</div>
                    <div class="fun-item">√</div>
                    <div class="fun-item">√</div>
                    <div class="fun-item">×</div>
                    <div class="fun-item">×</div>
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div
                  class="ant-card membership-card card-copper cursor"
                  :class="selected==66?'card-selected':''"
                  @click="select(66)">
                  <div class="membership-card-header">Pro会員（1200円/年）</div>
                  <div class="membership-card-body">
                    <div class="fun-item">{{ $t('user.membership.limit.none') }}</div>
                    <div class="fun-item">√</div>
                    <div class="fun-item">√</div>
                    <div class="fun-item">×</div>
                    <div class="fun-item">×</div>
                  </div>
                </div>
              </a-col>
              <a-col :span="6">
                <div
                  class="ant-card membership-card card-gold cursor"
                  :class="selected==2?'card-selected':''"
                  @click="select(2)">
                  <div class="membership-card-header">Pro+会員（1000円/月）</div>
                  <div class="membership-card-body">
                    <div class="fun-item">{{ $t('user.membership.limit.none') }}</div>
                    <div class="fun-item">√</div>
                    <div class="fun-item">√</div>
                    <div class="fun-item">√</div>
                    <div class="fun-item">√</div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>

        </div>

      </div>

    </div>

    <div style="height: 38px;margin-top: 12px;">
      <div
        v-if="selected>0">
        <span v-if="selected==1||selected==2">開始月：</span>
        <a-input-number
          v-model="num"
          v-if="selected==1||selected==2"
          @change="handleChangeNum"
          :min="1"
          :max="12"
          style="width: 80px;"></a-input-number>
        <span style="margin-left: 8px;margin-right: 20px;" v-if="selected==1||selected==2">月</span>
        <span style="line-height: 32px;" >{{ $t('user.membership.driver.selected').replace('{num}',selectedRows.length) }}</span>
        <a style="line-height: 32px;" @click="showDetail">{{ $t('user.membership.show.details') }}</a>
      </div>
    </div>
    <div style="margin-top: 10px;clear: both;">
      <span style="line-height: 36px;">合计：{{ selected === 0 ? 0 :(selected==66?1200*selectedRows.length:selected * 500 * num*selectedRows.length ) }}円</span>
      <a-button :disabled="selected===0" :type="selected===0?'':'primary'" class="float-right" @click="startPay">
        {{ selected === 0 ? $t('user.pay.none') : $t('user.pay.now') }}
      </a-button>
    </div>
    <div style="text-align: center;margin-top: 22px;">機能の違いや料金の具体的な説明は、<a href="https://www.reflexwireless.jp/cloud-manager-pricing/" target="_blank">こちらの</a>をクリックしてご覧ください。</div>
    <a-drawer
      title="支払いは会員に"
      placement="right"
      :width="300"
      :closable="true"
      :visible="payShow"
      :get-container="false"
      @close="onClose"
    >
      <div style="line-height: 40px;text-align: center;">{{ $t('user.membership.pay') }}{{ selected==66?1200*selectedRows.length:selected * 500 * num*selectedRows.length }}円</div>
      <div v-if="paySuccess" style="text-align: center">
        <a-result
          status="success"
          :title="$t('user.membership.pay.success')"
        ></a-result>
        <div></div>
      </div>
      <div id="paypal-button-container" v-else></div>
    </a-drawer>
  </div>
</template>

<style scoped lang='less'>

.membership-card {
  border-radius: 8px;
  height: 238px;

  border: 1px solid #eaeaea;

  .membership-card-header {
    text-align: center;
    font-size: 1.0rem;
    line-height: 40px;
    font-weight: bold;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .membership-card-body {
    padding: 8px;
    text-align: center;
  }

  .membership-card-body .fun-item {
    border-bottom: 1px solid #eee;
    line-height: 36px;
  }

  .membership-card-body .fun-item:last-child {
    border-bottom: none;
  }
}

.card-grey {

  .membership-card-header {
    background-color: #eee;
    color: #333;
  }
}

.card-copper {

  .membership-card-header {
    background-color: #FF9900;
    color: #fff;
  }
}

.card-gold {

  .membership-card-header {
    background-color: #FFCC00;
    color: #fff;
  }
}

.card-selected {
  border: 2px solid #ff0000;
}

.cursor {
  cursor: pointer;
}
</style>
