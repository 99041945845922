<script>
import moment from 'moment'

export default {
  name: 'UpgradeDetail',
  props: ['selectedRows', 'selected', 'num'],
  data () {
    return {
      columns: [{
        title: this.$t('user.membership.target.user'),
        dataIndex: 'name',
        align: 'center'
      }, {
        title: this.$t('user.membership.target.level'),
        dataIndex: 'level',
        align: 'center'
      }, {
        title: this.$t('user.membership.target.expireDate'),
        dataIndex: 'expireDate',
        align: 'center'
      }],
      data: []
    }
  },
  created () {
    console.log(this.selectedRows)
    const mData = []
    const selectedRows = this.selectedRows.slice(0)
    for (const item of selectedRows) {
      let newLevel = 1
      let levelName = ''
      let expireDate = ''
      if (this.selected === 66) {
        if (item.user_level <= 1) {
          newLevel = 1
        } else {
          newLevel = item.user_level
        }
      } else {
        if (item.user_level <= this.selected) {
          newLevel = this.selected
        } else {
          newLevel = item.user_level
        }
      }
      console.log(newLevel)
      switch (newLevel) {
        case 1:
          levelName = this.$t('user.membership.level.pro')
          break
        case 2:
          levelName = this.$t('user.membership.level.plus')
          break
      }
      if (item.level_expire && moment().isBefore(moment(item.level_expire))) {
        if (this.selected === 66) {
          expireDate = moment(item.level_expire).add(12, 'month').format('YYYY-MM-DD')
        } else {
          expireDate = moment(item.level_expire).add(this.num, 'month').format('YYYY-MM-DD')
        }
      } else {
        if (this.selected === 66) {
          expireDate = moment().add(12, 'month').format('YYYY-MM-DD')
        } else {
          expireDate = moment().add(this.num, 'month').format('YYYY-MM-DD')
        }
      }
      mData.push({
        name: item.name,
        level: levelName,
        expireDate: expireDate
      })
    }
    this.data = mData
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px;">
    <a-table :columns="columns" :dataSource="data" :scroll="{y:480}" :pagination="false"></a-table>
  </div>
</template>

<style scoped lang='less'>

</style>
